<template>
  <div class="box_12 flex-col">
    <img v-lazy="info.companyLogoUrl" alt="" />
    <span class="text_111">{{ info.serviceName }}</span>
    <span class="text_112">/{{ info.companyName }}</span>
  </div>
</template>

<script>
export default {
  props: ['size', 'info'],
  name: 'ServeListItem'
}
</script>

<style lang="scss" scoped>
.box_12 {
  width: 924px;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 58px;
  cursor: pointer !important;

  img {
    border-radius: 35px;
    width: 70px;
    height: 70px;
    margin-right: 20px;
    object-fit: contain;
  }

  .text_111 {
    height: 25px;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    line-height: 25px;
  }

  .text_112 {
    width: 99px;
    height: 30px;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 22px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 30px;
  }
}
</style>
