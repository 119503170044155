<template>
  <div class="box_12 flex-col" @click="$emit('moreAction')">
    <div class="group_41 flex-row">
      <span class="text_59">{{ info.name }}</span>
      <div class="text-wrapper_13" v-if="setPriceNum(info.budget) !== '0'">
        <span class="text_60">{{ setPriceNum(info.budget) }}</span>
      </div>
      <span class="text_62" v-if="setPriceNum(info.budget) !== '0'">预算</span>
    </div>
    <span class="text_63 ellipsis-line">{{ info.introduction }}</span>
    <div class="group_42 flex-row">
      <div class="text-wrapper_14 flex-col">
        {{ getStatusList(info.status) }}
      </div>
      <div class="text-wrapper_15">
        <span class="text_65" v-if="info.bidTenderCount">{{ info.bidTenderCount }}投标</span>
        <span class="white-space" v-if="info.bidAveragePrice">·</span>
        <span class="text_66">{{ setPriceNum(info.bidAveragePrice) }}</span>
        <span class="text_67" v-if="info.bidAveragePrice">(均)</span>
      </div>
      <span class="text_68"
        >{{ info.customerName }}&nbsp;&nbsp;&nbsp;{{ formatTimeFilter(info.publishDate, 'MM.DD') }}
      </span>
    </div>
    <div class="tag">{{ getMaxForthLengthStr(tagName) }}</div>
  </div>
</template>

<script>
const getStrLength = str => {
  let num = 0
  let a = str.split('')
  for (var i = 0; i < a.length; i++) {
    if (a[i].charCodeAt(0) < 299) {
      num++
    } else {
      num += 2
    }
  }
  return num
}

import {statusList} from '@/config/status.js'
import {setPriceNum, formatTimeFilter} from '@/utils/index'
export default {
  props: ['size', 'info', 'tagName'],
  name: 'ProjectListItem',
  methods: {
    setPriceNum,
    getStatusList(key) {
      return statusList[key]
    },
    formatTimeFilter,
    getMaxForthLengthStr(str) {
      if (!str || typeof str != 'string') return ''
      const len2 = getStrLength(str.slice(0, 2))
      const len3 = getStrLength(str.slice(0, 3))
      const len4 = getStrLength(str.slice(0, 4))
      if (len4 <= 4) {
        return str.slice(0, 4)
      }
      if (len3 <= 4) {
        return str.slice(0, 3)
      }
      if (len2 <= 4) {
        return str.slice(0, 2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  position: absolute;
  left: -10px;
  top: 18px;
  height: 40px;
  line-height: 40px;
  background: #5ad3cd;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  padding: 0 10px;
  border-radius: 0px 100px 100px 0px;
}

.tag:after {
  position: absolute;
  left: 0;
  bottom: -15px;
  content: '';
  height: 8px;
  border-left: 11px solid transparent;
  border-right: 0 solid transparent;
  border-top: 8px solid #27b7b0;
}

.box_12 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  width: 580px;
  height: 163px;
  justify-content: flex-center;
  position: relative;
  cursor: pointer;
  .group_41 {
    width: 496px;
    height: 36px;
    margin: 20px 0 0 60px;
    display: flex;

    .text_59 {
      width: 96px;
      height: 33px;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 24px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;
      flex: 1;
    }

    .text-wrapper_13 {
      height: 33px;
      overflow-wrap: break-word;
      font-size: 0;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      line-height: 33px;

      .text_60 {
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(255, 0, 0, 1);
        font-size: 24px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
      }

      .text_61 {
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(255, 0, 0, 1);
        font-size: 20px;
        font-family: PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
      }
    }

    .text_62 {
      height: 36px;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 36px;
      margin-left: 6px;
    }
  }

  .text_63 {
    width: 496px;
    height: 36px;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 36px;
    margin: 5px 0 0 60px;
  }

  .group_42 {
    width: 496px;
    height: 30px;
    margin: 16px 0 20px 60px;
    display: flex;

    .text-wrapper_14 {
      border-radius: 15px;
      height: 30px;
      border: 1px solid rgba(90, 211, 205, 1);
      overflow-wrap: break-word;
      color: rgba(90, 211, 205, 1);
      font-size: 16px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      padding: 0 19px;
      line-height: 28px;
      text-align: center;
    }

    .text-wrapper_15 {
      width: 144px;
      height: 25px;
      overflow-wrap: break-word;
      font-size: 0;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
      margin: 3px 0 0 10px;
      flex: 1;

      .text_65 {
        width: 144px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }

      .text_66 {
        width: 144px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(255, 114, 76, 1);
        font-size: 18px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }

      .text_67 {
        width: 144px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
      }
    }

    .text_68 {
      height: 25px;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      line-height: 25px;
    }
  }
}
.box_12:hover {
  box-shadow: 0px 7px 8px 0px rgba(229, 229, 229, 0.5);
}
.white-space {
  width: 10px;
  margin-left: 3px;
  margin-right: 5px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
</style>
