<template>
  <div class="example-header-box" :class="size == 'mini' ? '' : 'min-box'" @click="$emit('moreAction')">
    <div class="title">
      {{ title }} <span class="number">({{ number }})</span>
    </div>
    <div class="description-box">
      <span class="description">{{ description }}</span>
      <span class="more-button"> 查看更多 </span>
      <img class="right_more" src="/img/right-more.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['size', 'title', 'number', 'description'],
  name: 'ExampleHeader'
}
</script>

<style lang="scss" scoped>
.example-header-box {
  .title {
    font-size: 34px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;

    .number {
      margin-left: 10px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }

  .description-box {
    flex: 1;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-family: SimSong-Regular, SimSong;
    font-weight: 400;
    color: #333333;
    margin-top: 19px;
    cursor: pointer;

    .description {
      flex: 1;
      color: #333333;
    }

    .more-button {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #007aff;
      cursor: pointer;

      i {
        border: 1px solid #007aff;
        border-radius: 10px;
        width: 18px;
        height: 18px;
      }
    }
    .right_more {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
  }
}

.min-box {
  display: flex;
  align-items: center;
  .description-box {
    flex: 1;
    margin-left: 20px;
    margin-top: 0;
  }

  .description {
    color: #999999;
  }
}
</style>
