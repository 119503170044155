<template lang="pug">
.content
  .content-firm(v-if="subTabSelect === 1")
    .options
      .label(style="width: 150px;") 充值金额:
      el-input(v-model="depositPrice" placeholder="请输入充值金额" style="width: 300px;")
    
    .btns
      .deposit(@click="userAccountRecharge(2)") 充值
    img(:src="paySrc" style="width: 240px; margin: 20px 0 20px 134px;display: block;" v-if="paySrc")
    //- p.pay-number(style="margin: 0 auto;display: block; text-align: center;margin: 0;" v-if="paySrc") 微信扫码，充值 {{ depositPrice }} 元”。 
    span(style="font-size: 26px;font-weight: 500;color: #333333;margin-left: 150px;" v-if="paySrc") 微信扫码, 充值&nbsp;
      span(class="pay-number" style="color: #ff0000; font-size: 38px;") {{depositPrice}} &nbsp;
      span 元
  .content-firm(v-if="subTabSelect === 2")
    .options
      .label(style="width: 150px;") 充值金额:
      el-input(v-model="depositPrice" placeholder="请输入充值金额" style="width: 300px;")
    .btns
      .deposit(@click="userAccountRecharge(1)") 前往充值
  .content-firm(v-if="subTabSelect === 3")
    .duigong
      .taps
        .tab-name 1
        .tab-name-line
        .tab-name 2
      .taps-content
        span(style="margin-bottom: 30px;display: inline-block;") · 使用您公司的银行账户，转账至全倾的银行收款账户
        .options(style="margin-bottom: 10px;")
          .label-text(style="width: 80px;") 收款户名:
          .params-text 北京全倾科技有限公司
        .options(style="margin-bottom: 10px;")
          .label-text(style="width: 80px;") 收款银行:
          .params-text 招商银行北京朝阳公园支行（联行号：308100005473）
        .options(style="margin-bottom: 30px;")
          .label-text(style="width: 80px;") 收款账号:
          .params-text 1109 4195 1610 902
        span(style="margin-bottom: 30px;display: inline-block;") · 等待1-5个工作日，客服核验汇款后将为您帐号充值
        br
        span(style="color: red;display: inline-block;") · 请务必在汇款时，在附言处填写您要充值的帐号，即您当前的注册帐号，可查看全倾网站的右上角即是。如未注明，则汇款将会被原路退回。
  .content-firm(v-if="subTabSelect === 4")
    .options
      .label(style="width: 180px;") 充值至用户 ID:
      el-input(v-model="userPhoneNumber" placeholder="请输入充值至用户 ID" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值金额:
      el-input(v-model="topUpAmount" placeholder="请输入充值金额" style="width: 300px;") 元
    .options
      .label(style="width: 180px;") 充值至账户:
      el-input(v-model="accountName" placeholder="请输入充值至账户" style="width: 300px;")
    .options
      .label(style="width: 180px;") 公司信用代码:
      el-input(v-model="creditCode" placeholder="请输入公司信用代码" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值方式:
      el-select(v-model="payType" placeholder="请选择充值方式" style="width: 300px;")
        el-option(key="3" label="对公转账" value="3")
    .options
      .label(style="width: 180px;") 充值来源账户:
      el-input(v-model="sourceAccountNumber" placeholder="请输入充值来源账户" style="width: 300px;")
    .options
      .label(style="width: 180px;") 来源账户名称:
      el-input(v-model="sourceAccountName" placeholder="请输入来源账户名称" style="width: 300px;")
    .options
      .label(style="width: 180px;") 充值来源银行:
      el-input(v-model="sourceAccountBank" placeholder="请输入充值来源银行" style="width: 300px;")
    .btns(style="padding-left: 30px;" @click="createRecord")
      .deposit 新增
  .pay-html(v-html="payHtml")
</template>
<script>
import {userAccountRecharge, rechargeSave} from '@/api/account'
import {currentLoginUser} from '@/api/project'
import {checkOrderPay} from '../../api/orderAccount'

export default {
  data() {
    return {
      depositPrice: '',
      phoneCode: '',
      userPhoneNumber: '',
      topUpAmount: '',
      payType: '',
      creditCode: '',
      accountName: '',
      sourceAccountBank: '',
      sourceAccountName: '',
      payTimer: null,
      wxPic: '',
      paySrc: '',
      sourceAccountNumber: '',
      alipay: '',
      payHtml: '',
      params: {
        phoneNumber: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    subTabSelect: Number
  },
  watch: {
    subTabSelect() {
      this.depositPrice = ''
      this.userPhoneNumber = ''
      this.topUpAmount = ''
      this.payType = ''
      this.creditCode = ''
      this.paySrc = ''
      this.accountName = ''
      this.payHtml = ''
      this.sourceAccountBank = ''
      this.sourceAccountName = ''
      this.sourceAccountNumber = ''
      this.wxPic = ''
    },
  },
  mounted() {
    currentLoginUser().then(res => {
      console.log('%c [ res ]-79', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      this.phoneNumber = res.data.phoneNumber
      this.params.phoneNumber = this.phoneNumber
    })
  },
  methods: {
    createRecord() {
      const params = {
        userPhoneNumber: this.userPhoneNumber,
        topUpAmount: this.topUpAmount,
        accountName: this.accountName,
        creditCode: this.creditCode,
        payType: this.payType, //充值方式 3-对公转账
        sourceAccountNumber: this.sourceAccountNumber, //充值来源账户
        sourceAccountName: this.sourceAccountName, //来源名称账户
        sourceAccountBank: this.sourceAccountBank //来源账户银行
      }
      rechargeSave(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
        } else {
          this.$message.success('新增成功')
          this.depositPrice = ''
          this.userPhoneNumber = ''
          this.topUpAmount = ''
          this.payType = ''
          this.paySrc = ''
          this.creditCode = ''
          this.accountName = ''
          this.payHtml = ''
          this.sourceAccountBank = ''
          this.sourceAccountName = ''
          this.sourceAccountNumber = ''
          this.wxPic = ''
        }
      })
    },
    getPayStatus() {
      if (!this.payTimer)
        this.payTimer = setInterval(() => {
          checkOrderPay({orderCode: this.orderCode}).then(res => {
            const {code, data, msg} = res
            console.log('%c [ res ]-382-「pay」', 'font-size:px;', res)
            if (code === 200 && data) {
              if (res.data.paySuccess) {
                this.$dialog.toast('购买成功')
                this.payTimer && clearInterval(this.payTimer)
                this.payTimer = null
                setTimeout(() => {
                  this.$router.push({
                    path: this.isReplace ? '/orderList?orderType=2' : '/techVideoDetail',
                    query: {id: courseId}
                  })
                }, 200)
              }
            } else {
              this.payTimer && clearInterval(this.payTimer)
              this.$dialog.toast(msg)
            }
          })
        }, 1000)
    },
    userAccountRecharge(type) {
      const params = {
        amount: this.depositPrice,
        payType: type,
        alipayReturnUrl: window.location.origin + '/#/' + 'my'
      }
      console.log('%c [ params ]-167-「recharge」', 'font-size:px;', params)
      userAccountRecharge(params).then(res => {
        if (res.code === 200) {
          if(this.subTabSelect === 1) {
            this.paySrc = res.data.payUrl
            this.orderCode = res.data.orderCode
            this.getPayStatus()
          }
          if(this.subTabSelect === 2) {
            this.payHtml = res.data.payUrl
            this.$nextTick(() => {
              document.forms[0].submit()
            })
          }
        }
        if (res.code !== 200) {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .duigong {
    display: flex;
    justify-content: flex-start;
    .taps {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    .taps-content {
      flex: 1;
    }
    .tab-name {
      background-color: #5ad3cd;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      align-items: center;
    }
    .tab-name-line {
      width: 0px;
      height: 103px;
      margin: 20px 0;
      border-left: 1px solid #2c3e50;
      opacity: 0.2;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .label {
    width: 180px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 18px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #5ad3cd;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #fff;
    align-items: center;
    display: flex;
    cursor: pointer;
    margin-left: 150px;
  }
  .pay-code {
    width: 178px;
    height: 178px;
    margin-bottom: 30px;
    margin-left: 150px;
    img {
      width: 178px;
      height: 178px;
    }
  }
}
.pay-html {
  position: fixed;
  left: 100000;
}
.pay-number {
  height: 70px;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff724c;
  line-height: 56px;
}
</style>
