<template lang="pug">
.content
  .content-firm
    .options
      .label(style="width: 230px;") 选择账户:
      el-select(v-model="account" placeholder="请选择账户" style="margin-right: 20px;" value-key="id")
        el-option(
          v-for="item in accountList"
          :key="item.id"
          :label="item.name"
          :value="item")
    .options(v-if="account.id")
      .label(style="width: 230px;")  现有金额:
      .params {{account.balance}} 元
    .options
      .label(style="width: 230px;") 提现金额:
      el-input(v-model="depositPrice" placeholder="请输入提现金额" style="width: 300px;")
    .options
      .label(style="width: 230px;") 提现至:
      .params
        .options
          .label 银行账户:
          el-input(v-model="companyAccountNumber" placeholder="请输入银行账户" style="width: 300px;")
        .options
          .label 账户名称:
          el-input(v-model="companyAccountName" placeholder="请输入账户名称" style="width: 300px;")
        .options
          .label 开户银行支行:
          el-input(v-model="companyAccountBankSubbranch" placeholder="请输入开户银行支行" style="width: 300px;")
      
    .options
      .label(style="width: 230px;") 短信验证:
      .params
        el-input(placeholder="短信验证码" v-model="phoneCode" class="change-password-input")
        .code-desc （发送至注册手机号）
        button(@click.prevent="getVerify()" class="code-btn" :disabled="!show")
          span(v-show="show") 获取验证码
          span(v-show="!show" class="count") {{ count }}
    .btns
      .deposit(@click="userAccountWithdraw") 申请提现
  sliderImgValidate(
    v-if="showSliderImgValidate && params.smallImage && params.bigImage"
    :yHeight="params.yHeight"
    :xwidth.sync="params.xwidth"
    :bigImage="params.bigImage"
    :smallImage="params.smallImage"
    :showSliderImgValidate.sync="showSliderImgValidate"
    @changeImgPosition="changeImgPosition"
    @changeImgPositionEnd="changeImgPositionEnd"
    @resetPicAgain="getSliderImgValidate"
  )
</template>
<script>
import {getVerifyCodeApi, slipPicSmallApi, checkSlipPicSmallApi,} from '@/api/index'
import sliderImgValidate from '@/components/sliderValidate'
import {currentLoginUser} from '@/api/project'
import {getCompanyAccountInfoApi, userAccountWithdraw} from '@/api/account.js'

export default {
  components: {
    sliderImgValidate
  },
  data() {
    return {
      timer: null,
      count: 0,
      show: true,
      price: '68.00',
      depositPrice: '',
      companyAccountNumber: '',
      companyAccountName: '',
      accountList: [],
      companyAccountBankSubbranch: '',
      account: {},
      phoneCode: '',
      showSliderImgValidate: false,
      biz: 6,
      params: {
        phoneNumber: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    currentLoginUser().then(res => {
      console.log('%c [ res ]-79', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      this.phoneNumber = res.data.phoneNumber
      this.params.phoneNumber = this.phoneNumber
    })
    // 企业账户
    getCompanyAccountInfoApi().then(res => {
      this.accountList = res.data
    })
  },
  methods: {
    userAccountWithdraw() {
      const params = {
        accountId: this.account.id,
        identityType: '企业',
        alipayAccount: this.alipayAccount,
        "amount": this.depositPrice, //提现金额
        "verifyCode": this.phoneCode, //验证码
        companyAccountNumber: this.companyAccountNumber,
        companyAccountName: this.companyAccountName,
        companyAccountBankSubbranch: this.companyAccountBankSubbranch,
      }
      userAccountWithdraw(params).then(res => {
        if (res.code === 200) {
          this.init()
          this.depositPrice = ''
          this.companyAccountNumber = ''
          this.companyAccountName = ''
          this.companyAccountBankSubbranch = ''
          this.account = {}
          this.phoneCode = ''
          this.$message.success('提现成功')
        } else {
          this.$message.error(res.msg)

        }
      })
    },
    // 修改滑块的水平坐标最终的值
    changeImgPosition(val) {
      this.params.xwidth = val
    },
    // 验证图片位置是否正确
    changeImgPositionEnd() {
      this.$nextTick(() => {
        const params = {}
        params.biz = this.biz
        params.phone = this.params.phoneNumber
        params.xwidth = this.params.xwidth || '0'
        this.showSliderImgValidate = false
        checkSlipPicSmallApi(params)
          .then(res => {
            if (res.msg == 'success') {
              this.$message.success('验证通过')
              this.params.xwidth = 0
              this.getCode()
            } else {
              // this.params.xwidth = 0
              this.getSliderImgValidate()
            }
          })
          .catch(err => {
            // this.params.xwidth = 0
            this.getSliderImgValidate()
          })
          .finally(() => {
            const {yHeight, xwidth, bigImage, smallImage} = this.$options.data().params
            this.params = {...this.params, yHeight, xwidth, bigImage, smallImage}
            console.log('%c [ this.params ]-150-「resetPayPassword」', 'font-size:px;', this.params)
          })
      })
    },
    // 获取图片验证划图图片链接
    getSliderImgValidate() {
      const params = {}
      this.showSliderImgValidate = true
      params.phone = this.params.phoneNumber
      // biz 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      params.biz = this.biz // 申请类型：1-登录密码次数超限；2-获取验证码
      slipPicSmallApi(params).then(res => {
        const params = {
          ...this.params,
          ...(res?.data || {})
        }
        this.$set(this, 'params', params)
      })
    },
    getVerify() {
      if (!this.account.id) {
        this.$message.warning('请选择企业账户')
        return
      }
      if (!this.depositPrice) {
        this.$message.warning('请输入提现金额')
        return
      }
      if (!this.companyAccountNumber) {
        this.$message.warning('请输入银行账户')
        return
      }
      if (!this.companyAccountName) {
        this.$message.warning('请输入账户名称')
        return
      }
      if (!this.companyAccountBankSubbranch) {
        this.$message.warning('请输入开户银行支行')
        return
      }
      this.showSliderImgValidate = true
      this.getSliderImgValidate()
    },
    getCode() {
      //axios请求
      // 验证码倒计时
      getVerifyCodeApi({phone: this.phoneNumber, biz: this.biz})
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pr {
  position: relative;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -100px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  padding-left: 10px;
  background-color: #fff;
  line-height: 36px;
  cursor: pointer;
}
.change-password-input {
  width: 300px;
}
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 180px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 18px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
}
</style>
