<template lang="pug">
.content
  .search-tab
    .search-account
      el-select(v-model="account" placeholder="请选择账户" style="margin-right: 20px;width: 180px;")
        el-option(
          v-for="item in accountOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id")
      el-select(v-model="status" placeholder="请选择变动" style="width: 180px;")
        el-option(
          v-for="item in statusList"
          :key="item.value"
          :label="item.name"
          :value="item.value")
    .search-code
      span(style="width: 48px;display: inline-block;") 搜索：
      el-select(v-model="searchTypeName" placeholder="请选择搜索类型" style="margin-right: 20px;width: 180px;")
        el-option(
          v-for="item in searchType"
          :key="item.value"
          :label="item.name"
          :value="item.value")
      el-input(v-model="searchInputValue" placeholder="请输入内容" style="width: 250px;")
      img.search-icon(src="/img/search-icon.png" @click="searchBtnClick")
  el-divider
  .content-firm(v-for="(item, index) in info" v-if="info.length > 0")
    .options
      .label 明细单号:
      .params {{ item.incomeAndExpensesId }}
    .options
      .label 金额变动:
      .params {{ item.amountChanged }}元
    .options
      .label 账户:
      .params {{ item.accountName }}
    .options
      .label 变动前余额:
      .params {{ item.accountBalanceBeforeChange }}
    .options
      .label 变动后余额:
      .params {{ item.accountBalanceAfterChange }}
    .options
      .label 变动原因:
      .params {{ item.changeReason }}
    .options(v-if="['支付', '退款'].includes(item.changeReason)")
      .label 订单号:
      .params {{ item.orderId }}
    .options(v-if="item.changeReason === '充值'")
      .label 充值单号:
      .params {{ item.topUpId }}
    .options(v-if="item.changeReason === '提现'")
      .label 提现单号:
      .params {{ item.withdrawId }}
    .options
      .label 变动时间:
      .params {{ item.createTime }}
    .options
      .label 用户ID:
      .params {{ item.userPhoneNumber }}
    el-divider(v-if="info.length && index < info.length - 1")
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
  .empty(v-if="info.length === 0")
    img.coupon-empty(src="/img/userinfo-icon/empty.png")
    span.coupon-empty-text 暂无数据
</template>
<script>
import { getAccountOptionsApi, getIncomeRecordApi, } from '../../api/account';
import { checkAuth } from '@/api/product';

const searchTypeTemplate = [{
  value: 1,
  name: '明细单号'
},
{
  value: 2,
  name: '订单号'
},
{
  value: 3,
  name: '充值单号'
},
{
  value: 4,
  name: '提现单号'
}]

export default {
  data() {
    return {
      isOperator: false,
      info: [],
      total: 0,
      page: 1,
      pageSize: 10,
      accountOptions: [],
      statusList: [
        {
          value: '全部',
          name: '全部'
        },
        {
          value: '充值',
          name: '充值'
        },
        {
          value: '提现',
          name: '提现'
        },
        {
          value: '支付',
          name: '支付'
        },
        {
          value: '退款',
          name: '退款'
        }
      ],
      searchType: [
        {
          value: 5,
          name: '用户id'
        },
        {
          value: 6,
          name: '账户'
        },
        {
          value: 7,
          name: '信用代码'
        }
      ],
      status: '',
      account: '',
      searchTypeName: '',
      searchInputValue: '',
    }
  },
  created() {
    // 是否是运营
    checkAuth({ and: true, authList: [1] }).then(res => {
      this.isOperator = res.code === 200; 
      this.searchType = res.code === 200 ? this.searchType : [...this.searchType, ...searchTypeTemplate]
    })
  },
  watch: {
    status() {
      this.page = 1
      this.search()
    },
    account() {
      this.page = 1
      this.search()
    },
    // searchInputValue() {
    //   this.page = 1
    //   this.search()
    // }
  },
  mounted() {
    this.getAccountOptions().then(() => {
      this.search()
    })
  },
  methods: {
    searchBtnClick() {
      if (this.searchTypeName === '') {
        this.$message.error('请选择搜索项')
        return
      }
      if (this.searchInputValue === '') {
        this.$message.error('请输入关键词')
        return
      }
      this.page = 1
      this.search()
    },
    // 账户下拉列表
    getAccountOptions() {
      return getAccountOptionsApi().then(res => {
        this.accountOptions = res.data;
        console.log('%c [ this.accountOptions ]-192-「incomeRecord」', 'font-size:px;', this.accountOptions)
      })
    },
    search() {
      const params = {
        accountId: this.account,
        changeReason: this.status,
        queryType: this.searchTypeName,
        queryContent: this.searchInputValue,
        pageNum: this.page,
        pageSize: this.pageSize,
      }
      getIncomeRecordApi(params).then(res => {
        const {records, total} = res.data;
          this.info = records;
          this.total = total;
      })
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 55px 88px;
  flex-direction: column;
  position: relative;
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .search-account,
  .search-code {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
  .search-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 160px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;
  }
  .options {
    margin-bottom: 32px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-left: 20px;
}
</style>
