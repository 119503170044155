<template lang="pug">
.content
  .content-firm(v-for="(item, index) in info" v-if="info.length > 0")
    .options
      .label 账户名称:
      .params {{ item.name }}
    .options
      .label 账户余额:
      .params 
        .price ¥
          span {{ item.balance }}
    .options
      .label 信用代码:
      .params {{ item.creditCode }}
    el-divider(v-if="info.length && index < info.length - 1")
  .empty(v-if="info.length === 0")
    img.coupon-empty(src="/img/userinfo-icon/empty.png" class="")
    span.coupon-empty-text 暂无数据
  .btns
    .recharge(@click="$emit('changType', 2, 3)") 去充值
    .deposit(@click="$emit('changType', 4, 2)") 去提现
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    info: {
      type: Array,
      default: []
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 110px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 26px;
    text-align: left;
  }
  .options {
    margin-bottom: 32px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 28px;
    text-align: left;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
}
</style>
