<template lang="pug">
.content
  .content-firm
    .options
      .label 现有收入:
      .params {{ myAccountIncomeInfo.balance }}元
    .options
      .label 提现金额:
      el-input(v-model="amount" placeholder="请输入提现金额" style="width: 300px;") 
      span(style="font-size: 22px;margin-left: 8px; line-height: 40px;") 元
    .options(v-if="amount && priceAccount && selectAccount === 'person'")
      .label  劳务所得税:
      .params {{ priceAccount.individualIncomeTax }}元
    .options(v-if="amount && priceAccount")
      .label  所得金额:
      .params {{ priceAccount.incomeAmount }}元
    .options
      .label  提现至:
      .params 
        el-button(:type="selectAccount === 'person' ? 'primary' : ''" round @click="selectAccount='person'") 个人账户
        el-button(:type="selectAccount !== 'person' ? 'primary' : ''" round @click="selectAccount='company';withdrawInfo()") 公司账户
    .options(v-if="selectAccount === 'person'")
      .label 个人支付宝账号:
      el-input(v-model="alipayAccount" placeholder="请输入支付宝账号" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡账号:
      el-input(v-model="companyAccountNumber" placeholder="请输入公司卡账号" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡名称:
      el-input(v-model="companyAccountName" placeholder="请输入公司卡名称" style="width: 300px;")
    .options(v-if="selectAccount !== 'person'")
      .label 公司卡开户银行:
      el-input(v-model="companyAccountBankSubbranch" placeholder="请输入公司卡开户银行" style="width: 300px;")
    .options
      .label  短信验证:
      .params
        el-input(placeholder="短信验证码" v-model="phoneCode" class="change-password-input")
        .code-desc （发送至注册手机号）
        button(@click.prevent="getVerify()" class="code-btn" :disabled="!show")
          span(v-show="show") 获取验证码
          span(v-show="!show" class="count") {{ count }}
    .btns
      .deposit(@click="incomeWithdraw") 提现
    .notice
      img(src="/img/userinfo-icon/notice.png" class="notice-icon")
      div
        .notice-content · 提现至个人账户，每次（日）超过500元的，需要在生成的提现记录处补充发票才可成功提现，低于500无需发票。开票可于本省的电子税务局在线开电子发票，如本省不支持在线开电子票，则需您线下去税务局现场开票。开票信息如下：公司名称：北京全倾科技有限公司；信用代码：91110105MA01R2C90Y；发票类型：普通发票。电子发票直接在提现记录处上传即可，纸质发票则需邮寄到全倾公司，邮寄地址为：北京市朝阳区建国门赛特广场一层赛特空间126室，18600896808，郭先生收。
        .notice-content · 提现至个人账户，每月超过800元的部分，将由全倾代缴个人劳务所得税。
  sliderImgValidate(
    v-if="showSliderImgValidate && params.smallImage && params.bigImage"
    :yHeight="params.yHeight"
    :xwidth.sync="params.xwidth"
    :bigImage="params.bigImage"
    :smallImage="params.smallImage"
    :showSliderImgValidate.sync="showSliderImgValidate"
    @changeImgPosition="changeImgPosition"
    @changeImgPositionEnd="changeImgPositionEnd"
    @resetPicAgain="getSliderImgValidate"
  )
</template>
<script>
import {getMyAccountIncomeApi, withdrawInfo, incomeWithdraw} from '../../api/account'
import {getVerifyCodeApi, slipPicSmallApi, checkSlipPicSmallApi,} from '@/api/index'
import {currentLoginUser} from '@/api/project'
import sliderImgValidate from '@/components/sliderValidate'


export default {
  components: {
    sliderImgValidate
  },
  data() {
    return {
      timer: null,
      count: 0,
      show: true,
      price: '',
      alipayAccount: '',
      amount: '',
      myAccountIncomeInfo: {},
      priceAccount: {},
      phoneCode: '',
      companyAccountName: '',
      companyAccountNumber: '',
      showSliderImgValidate: false,
      biz: 6,
      params: {
        phoneNumber: '',
        yHeight: '0',
        xwidth: '0', //滑块的位置坐标
        bigImage: '',
        smallImage: ''
      },
      companyAccountBankSubbranch: '',
      selectAccount: 'person'
    }
  },
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mounted() {
    this.init()
    currentLoginUser().then(res => {
      console.log('%c [ res ]-79', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      this.phoneNumber = res.data.phoneNumber
      this.params.phoneNumber = this.phoneNumber
    })
  },
  watch: {
    amount() {
      this.withdrawInfo()
    }
  },
  methods: {
    // 修改滑块的水平坐标最终的值
    changeImgPosition(val) {
      this.params.xwidth = val
    },
    withdrawInfo() {
      withdrawInfo({
        withdrawAmount: this.amount,
        identityType: this.selectAccount === 'person' ? '个人账户' : '企业账户'
      }).then(res => {
        this.priceAccount = res.data
        console.log(
          '%c [ this.priceAccount  ]-86',
          'font-size:13px; background:pink; color:#bf2c9f;',
          this.priceAccount
        )
      })
    },
    // 验证图片位置是否正确
    changeImgPositionEnd() {
      this.$nextTick(() => {
        const params = {}
        params.biz = this.biz
        params.phone = this.params.phoneNumber
        params.xwidth = this.params.xwidth || '0'
        this.showSliderImgValidate = false
        checkSlipPicSmallApi(params)
          .then(res => {
            if (res.msg == 'success') {
              this.$message.success('验证通过')
              this.params.xwidth = 0
              this.getCode()
            } else {
              // this.params.xwidth = 0
              this.getSliderImgValidate()
            }
          })
          .catch(err => {
            // this.params.xwidth = 0
            this.getSliderImgValidate()
          })
          .finally(() => {
            const {yHeight, xwidth, bigImage, smallImage} = this.$options.data().params
            this.params = {...this.params, yHeight, xwidth, bigImage, smallImage}
            console.log('%c [ this.params ]-150-「resetPayPassword」', 'font-size:px;', this.params)
          })
      })
    },
    // 获取图片验证划图图片链接
    getSliderImgValidate() {
      const params = {}
      this.showSliderImgValidate = true
      params.phone = this.params.phoneNumber
      // biz 0-注册获取手机验证码，1-验证码登录获取手机验证码，2-找回密码获取手机验证码，3-登录密码次数超限
      params.biz = this.biz // 申请类型：1-登录密码次数超限；2-获取验证码
      slipPicSmallApi(params).then(res => {
        const params = {
          ...this.params,
          ...(res?.data || {})
        }
        this.$set(this, 'params', params)
      })
    },
    init() {
      getMyAccountIncomeApi().then(res => {
        this.myAccountIncomeInfo = res.data
      })
    },
    incomeWithdraw() {
      const params = {
        amount: this.amount,
        identityType: this.selectAccount === 'person' ? '个人账户' : '企业账户',
        verifyCode: this.phoneCode
      }
      if (this.selectAccount === 'person') {
        params.alipayAccount = this.alipayAccount
      } else {
        params.companyAccountName = this.companyAccountName
        params.companyAccountNumber = this.companyAccountNumber
        params.companyAccountBankSubbranch = this.companyAccountBankSubbranch
      }
      incomeWithdraw(params).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.amount = ''
        this.priceAccount = {}
        this.init()
        this.$message.success('提现成功')
      })
    },
    getVerify() {
      if (this.phoneNumber === '') {
        this.$message.warning('请输入手机号')
        return
      }
      if (!/^1[3-9]\d{9}$/.test(this.phoneNumber)) {
        this.$message.warning('请输入正确手机号')
        return
      }
      this.showSliderImgValidate = true
      this.getSliderImgValidate()
    },
    getCode() {
      //axios请求
      // 验证码倒计时
      getVerifyCodeApi({phone: this.phoneNumber, biz: 6})
      if (!this.timer) {
        this.count = 60
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pr {
  position: relative;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -100px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  padding-left: 10px;
  background-color: #fff;
  line-height: 36px;
  cursor: pointer;
}
.change-password-input {
  width: 300px;
}
.content {
  width: 100%;
  height: 100;
  padding: 55px 88px;
  flex-direction: column;
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    span {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .recharge {
    margin-right: 26px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 40px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .label {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #acacac;
    line-height: 40px;
    text-align: left;
  }
  .code-desc {
    width: 230px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 16px;
    color: #acacac;
    line-height: 22px;
    text-align: left;
    margin-top: 4px;
  }
  .options {
    margin-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: flex-start;
  }
  .params {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 40px;
    text-align: left;
    position: relative;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .coupon-empty-text {
    font-family: PingFang HK, PingFang HK;
    font-weight: 500;
    font-size: 20px;
    color: #acacac;
    line-height: 23px;
  }
  .coupon-empty {
    width: 96px;
    height: 96px;
    margin-bottom: 9px;
    display: block;
  }
  .empty {
    margin: 100px auto;
    text-align: center;
  }
}
.notice {
    width: 1080px;
    padding: 20px;
    background-color: #f6f6f8;
    display: flex;
    justify-content: flex-start;
    border-radius: 12px;
    margin-top: 20px;
    margin: 30px auto;
    .notice-icon {
      width: 48px;
      height: 48px;
    }
    .notice-content {
      flex: 1;
      margin-left: 30px;
      margin-top: 12px;
      line-height: 24px;
      font-size: 24px;
    }
  }
</style>
