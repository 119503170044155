<template lang="pug">
.content
  .desc 账户余额
  .price ¥
    span {{ info.balance }}
  .btns
    .recharge(@click="$emit('changType', 2)") 去充值
    .deposit(@click="$emit('changType', 4)") 去提现
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    info: {
      type: Object,
      default: {}
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  height: 100;
  display: flex;
  flex-direction: column;
  .desc {
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #333333;
    line-height: 26px;
    text-align: center;
    margin: 75px auto 12px;
  }
  .price {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 137px;
    span {
      font-size: 56px;
      line-height: 78px;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 74px;
  }
  .recharge {
    margin-right: 56px;
    background: #ff724c;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .deposit {
    background: #ffded6;
    border-radius: 34px 34px 34px 34px;
    padding: 0 24px;
    height: 38px;
    line-height: 38px;
    font-family: PingFang HK, PingFang HK;
    font-weight: 600;
    font-size: 22px;
    color: #ff724c;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}
</style>
