<template lang="pug">
.content
  .common
    .options
      .label 
        span.icon  ¥
        span {{info.totalAmount}}
      .name 收入金额
    .options
      .label {{total}}
      .name 记录条数
    .options
      .label
        span.icon  ¥
        span {{info.alreadyValidIncomeAmount}}
        span.num /{{info.alreadyValidIncomeTotal}}条
      .name 已生效
    .options
      .label
        span.icon  ¥
        span {{info.waitingValidIncomeAmount}}
        span.num  /{{info.waitingValidIncomeTotal}} 条
      .ready-used 
        .name 待生效 
        el-tooltip(content="订单超过可退款期限，用户没有退款或申请中的退款时，佣金即生效" trigger="hover" placement="bottom")
          i(class="el-icon-question")
  .tabs
    el-tabs(v-model="tabActiveName" @tab-click="tabClick")
      el-tab-pane(label="全部记录" name= '-1') 
      el-tab-pane(label="创作分佣" name= '1') 
      el-tab-pane(label="代销分佣" name= '2') 
      el-tab-pane(label="分享分佣" name= '3') 
      el-tab-pane(label="导流分佣" name= '4') 
      el-tab-pane(label="推广分佣" name= '5')
    .income-search-input 
      el-input(v-model="searchInputValue" placeholder="请输入内容" class="")
      el-button(type="primary" style="margin-left: 10px" @click="search") 搜索
  .list(v-for="item in info.items")
    .options
      .label 收入单号:
      .params {{ item.incomeId }}
    .options
      .label 收入金额:
      .params 
        span(style="color: red;") {{ item.incomeAmount }}
        span 元
    .options
      .label 收入类型:
      .params {{ item.incomeType }}
    .options
      .label 购买人帐号:
      .params {{ item.buyUserPhoneNumber }}
    .options(v-if="item.salePlatform")
      .label 销售平台:
      .params {{ item.salePlatform }}
    .options(v-if="item.platformUserId")
      .label 主播平台号:
      .params {{ item.platformUserId }}
    .options(v-if="item.orderId")
      .label 订单号:
      .params {{ item.orderId }}
    .options
      .label 订单金额:
      .params {{ item.orderPrice }}
    .options(v-if="item.discounts.length > 0")
      .label 使用优惠:
      .params 
        el-tag(v-for="tag in item.discounts" :key="tag" type="success" style="margin-right:5px;") {{tag}}
    .options
      .label 下单时间:
      .params {{ item.orderTime }}
    .options
      .label 收入人帐号:
      .params {{ item.userPhoneNumber }}
    .options(v-if="item.incomeLevel > 1")
      .label 佣金层级:
      .params {{ item.incomeLevel }}
    .options(v-if="item.precedeLevelOneIncomeUserPhoneNumber")
      .label 前序分佣人:
      .params {{ item.precedeLevelOneIncomeUserPhoneNumber }} {{item.precedeLevelTwoIncomeUserPhoneNumber ? `>${item.precedeLevelTwoIncomeUserPhoneNumber}` : ''}}
    .options
      .label 状态:
      .params  
        el-tag(:type="statusTagMap[item.status]" style="margin-right:5px;") {{statusTextMap[item.status]}}
    .options(v-if="item.invalidReason")
      .label 失效原因:
      .params {{ item.invalidReason }}
        
  el-pagination(
    class="el-pagination"
    background
    :page-size="pageSize"
    :pager-count="11"
    layout="prev, pager, next"
    @current-change="currentChange"
    :total="total"
    style="text-align: right;"
  )
</template>
<script>
import { getIncomeRecordListApi } from '../../api/account'

export default {
  data() {
    return {
      total: 0,
      page: 1,
      pageSize: 10,
      tabActiveName: '-1',
      searchInputValue: '',
      info: {},
      statusTagMap: {
        1: 'warning',
        2: 'success',
        3: 'info',
      },
      statusTextMap: {
        1: '待生效',
        2: '已生效',
        3: '已失效',
      },
    }
  },
  created() {
    this.search();
  },
  mounted() {},
  methods: {
    search() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.page,
        type: +this.tabActiveName,
        queryContent: this.searchInputValue
      }
      getIncomeRecordListApi(params).then(res => {
        this.info = res.data;
        this.total = res.data.total;
      })
    },
    tabClick(val) {
      this.tabActiveName = val.name
      this.page = 1
      this.search()
    },
    currentChange(val) {
      this.page = val
      this.search()
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: relative;
  .common {
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    .options {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-around;
      text-align: center;
    }
    .ready-used {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .name {
        margin-right: 6px;
      }
      .el-tooltip {
        cursor: pointer;
      }
    }
    .label {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      margin-bottom: 6px;
      vertical-align: bottom;
      align-items: flex-end;
      span {
        font-weight: 500;
        font-size: 20px;
      }
      .icon {
        font-size: 14px;
        margin-right: 2px;
      }
      .num {
        font-size: 16px;
      }
    }
  }
  .list {
    margin-bottom: 70px;
    border-bottom: 1px solid #f7f4f8;
    .label {
      width: 160px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 22px;
      color: #acacac;
      line-height: 26px;
      text-align: left;
    }
    .options {
      margin-bottom: 12px;
      display: flex;
      align-content: center;
      justify-content: flex-start;
    }
    .params {
      font-family: PingFang HK, PingFang HK;
      font-weight: 600;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
      text-align: left;
    }
  }
  .name {
    font-size: 16px;
  }
  .el-pagination {
    position: absolute;
    right: 20px;
    bottom: 50px;
  }
}
.tabs {
  margin-bottom: 10px;
  position: relative;
  .income-search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute; 
    right : 0; 
    bottom: 20px; 
  }
}
</style>
