<template>
  <div class="image-text_49 flex-row justify-between">
    <img class="box_23 flex-col" v-lazy="info.companyLogoUrl" />
    <div class="text-group_49 flex-col justify-between">
      <div class="text-wrapper_86 flex-row justify-between">
        <span class="text_108">{{ info.productName }}</span>
        <span class="text_109">/{{ info.companyName }}</span>
      </div>
      <span class="text_110 ellipsis-line">{{ info.productIntroduction }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['size', 'info'],
  name: 'ProductListItem'
}
</script>

<style lang="scss" scoped>
.image-text_49 {
  width: 568px;
  height: 72px;
  margin-top: 58px;
  cursor: pointer;
  .box_23 {
    border-radius: 35px;
    width: 70px;
    height: 70px;
    margin-top: 6px;
    margin-right: 10px;
    object-fit: contain;
  }

  .text-group_49 {
    width: 496px;
    height: 72px;

    .text-wrapper_86 {
      width: 253px;
      height: 33px;

      .text_108 {
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 24px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
      }

      .text_109 {
        width: 81px;
        height: 25px;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        line-height: 25px;
        margin-top: 4px;
        margin-left: 5px;
      }
    }

    .text_110 {
      width: 496px;
      height: 36px;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 36px;
      margin-top: 3px;
    }
  }
}
</style>
