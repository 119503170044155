import request from '@/utils/request'
/**
 *  服务模块 api
 */
// 服务列表
export const serveListApi = params =>
  request({
    url: '/service/list',
    method: 'get',
    params
  })

// 服务详情
export const getServeDetailApi = params =>
  request({
    url: `/service/${params.id}/detail`,
    method: 'get'
  })

// 保存服务信息
export const saveServeApi = params =>
  request({
    url: '/service/save',
    method: 'post',
    data: params
  })
